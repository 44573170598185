export const DEFAULT_DASHBOARD_PARAMETERS: DashboardConfiguration = {
    language: '',
    timezone: '',
    hotel: {
        roomCount: 10,
        mainCurrency: 'EUR'
    },
    settings: {
        baseUrl: process.env.REACT_APP_BASE_URL, // http://localhost/dashboard
        apiUrl: process.env.REACT_APP_API_URL, // http://localhost:5285
        apiKey: process.env.REACT_APP_API_KEY, // http://localhost:5285
        extranet: process.env.REACT_APP_EXTRANET,
        extranetUrl: process.env.REACT_APP_EXTRANET_URL, // /Home/DashboardStats
        randomData: process.env.REACT_APP_RANDOM_DATA
    },
    labels: {
        pastWeek: 'The past week',
        pastMonth: 'The past month',
        nextXDays: 'Next {number} days',
        theNextXDays: 'The next {number} days',
        calendarViewLeading: 'view',
        calendarViewBtn: 'Calendar',
        barChartViewBtn: 'Bar chart',
        period: 'period',
        Stays: {
            title: 'Stays',
            bookingLink: 'See my reservations',
            arrivals: 'Today’s arrivals',
            departures: 'Today’s departure',
            arrivalsTitle: '{number} arrivals scheduled for today',
            departuresTitle: '{number} departures scheduled for today',
            guestCount: '{guestCount} guests',
            roomCount: '{roomCount} rooms'
        },
        BookingActivity: {
            title: 'Booking Activity',
            subtitle:
                'Showcase your recent booking activity and revenue performance through D-EDGE Solutions',
            revenues: 'Bookings',
            cancellations: 'Canceled bookings',
            dailyPickUp: 'Daily pick up',
            nightsBooked: 'Nights booked',
            incomes: 'Revenue',
            nightsCancelled: 'Nights cancelled',
            revenueLost: 'Lost revenue',
            fullReport: 'See full report',
            DataCruncherTitle: 'Fancy some more data to crunch ?',
            DataCruncherText:
                'Check out our data intelligence products and how you can improve your business',
            DataCruncherLink: 'Discover now',
            LastUpdateMessage: 'Last update yesterday 23h59 UTC'
        },
        OccupationPlanning: {
            title: 'Activity forecast',
            subtitle:
                'Showcase your future booking activity and potential revenue performance through D-EDGE solutions',
            digitalOccupancyRate: 'Digital occupancy rate',
            provisionalRevenue: 'Estimated revenue',
            managePlanning: 'Manage planning'
        },
        Calendar: {
            roomForSale: 'Room available for sale',
            yearToYear: 'YoY',
            dailyPickUp: 'Daily pick-up',
            mostValuableRate: 'Most valuable rate',
            lowestRoomPrice: 'Lowest room price',
            highestRoomPrice: 'Highest room price'
        }
    }
};
