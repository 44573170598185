import { DEFAULT_DASHBOARD_PARAMETERS } from 'config';
import { merge } from 'lodash';

export const getLanguage = () => globalDashboard.language || 'en';
export const getTimezone = () => globalDashboard.timezone || 'Europe/Paris';

export const generateApiUrl = (url: string): string => {
    if (globalDashboard.settings.extranet === 'false') {
        // address for local development using a random Hotel Id
        // e.g.: https://localhost:7285/Hotel/15102/Booking/GetHotelBookingActivity/2022-11-01/2022-11-08/true
        return `${globalDashboard.settings.apiUrl}/Hotel/15102/${url}`;
    }

    // address from the Extranet to retrieve dashboard api stats
    // e.g.: https://extranet.availpro.com/Home/DashboardStats?url=Booking/GetHotelBookingActivity/2022-11-01/2022-11-08/true
    return `${globalDashboard.settings.extranetUrl}?url=${encodeURIComponent(url)}`;
};

export const getRandomDataFromApi = () => globalDashboard.settings.randomData === 'true';

// Merge default config and specific config to ensure a complete config
export let globalDashboard = merge(DEFAULT_DASHBOARD_PARAMETERS, window.dashboard);

export const reloadConfiguration = () => {
    globalDashboard = merge(DEFAULT_DASHBOARD_PARAMETERS, window.dashboard);
};
