import { Spinner } from 'atoms/Spinner';

type colorLevels = 'success' | 'warning' | 'alert' | 'neutral';
type titleInfoColormap = {
    [k in colorLevels]: { color: string };
};
type badgedInfoColorMap = {
    [k in colorLevels]: { color: string };
};

const TitleInfoColorMap: titleInfoColormap = {
    success: { color: 'green' },
    warning: { color: 'orange' },
    alert: { color: 'red' },
    neutral: { color: '' }
};

const BadgedInfoColorMap: badgedInfoColorMap = {
    success: { color: 'sprint-green' },
    warning: { color: 'red' },
    alert: { color: 'red' },
    neutral: { color: '' }
};

interface IDayBadgedInfoProps {
    isLoading: boolean;
    header: string;
    title: string;
    badgeInfo: string;
    titleLevel: colorLevels;
    badgeLevel: colorLevels;
}

const DayBadgedInfo = (props: IDayBadgedInfoProps) => {
    const titleColor = TitleInfoColorMap[props.titleLevel].color;
    const badgeColor = BadgedInfoColorMap[props.badgeLevel].color;
    return (
        <>
            <div className="key-value-label">
                <span className="key-value-label-text">{props.header}</span>
            </div>
            {props.isLoading ? (
                <Spinner id={''} size={1} align="left" />
            ) : (
                <div className="key-value-content">
                    <span
                        className={`key-value-content-text key-value-content-text--${titleColor}`}
                    >
                        {props.title}
                    </span>
                    <span className={`avp-badge badge--rounded badge--${badgeColor} badge--solid`}>
                        {props.badgeInfo}
                    </span>
                </div>
            )}
        </>
    );
};

export default DayBadgedInfo;
