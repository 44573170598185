import { ESpinnerSize, Spinner } from 'atoms/Spinner';

export const CardItem = ({
    label,
    content,
    isLoading = false
}: {
    label: string;
    content: number | string;
    isLoading?: boolean;
}): JSX.Element => {
    return (
        <div className="avp-key-value">
            <div className="key-value-label">
                <span className="key-value-label-text">{label}</span>
                {/*<i className="key-value-icon fas fa-up-right" aria-hidden="true"></i>
                <span className="u-visually-hidden">Increasing</span>*/}
            </div>

            <div className="key-value-content" style={{ display: isLoading ? 'none' : '' }}>
                <span className="key-value-content-text">{content}</span>
                {/*<span className="avp-badge badge--sprint-green badge--solid">+8,3%</span>*/}
            </div>

            <div hidden={!isLoading}>
                <Spinner size={ESpinnerSize.small} />
            </div>
        </div>
    );
};
