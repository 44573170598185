import { selectedDayAtom } from 'config/jotaiConfig';
import track from 'helpers/tracker';
import { useAtom } from 'jotai';
import { padStart } from 'lodash';
import { DateTime } from 'luxon';

export interface ICalendarData {
    days: IDayData[];
}

export interface IDayData {
    date: DateTime;
    bookedRoomsCount: number;
    hotelRoomsCount: number;
}

const CalendarBody = (props: ICalendarData) => {
    return (
        <div className="calendar-body">
            {props.days.map((day, index) => (
                <DayCell key={`day-${index}`} values={day} />
            ))}
        </div>
    );
};

interface IDayCellProps {
    values: IDayData;
}

const progressBarStyle = (date: DateTime, percentage: number) => {
    if (date.diff(DateTime.now(), 'days').days < -1) return 'disabled';
    if (percentage < 30) return 'red';
    if (percentage < 60) return 'orange';
    return 'green';
};

const dayStyle = (currentDate: DateTime, selectedDay: DateTime) => {
    let cellStyle = '';

    if (currentDate.diff(DateTime.now(), 'days').days < -1) {
        cellStyle += ' is-disabled';
    }

    if (currentDate.toISODate() === selectedDay.toISODate()) {
        cellStyle += ' is-current';
    }

    return cellStyle;
};

const DayCell = (props: IDayCellProps) => {
    const [selectedDay] = useAtom(selectedDayAtom);
    const normalisedDay = padStart(props.values.date.day.toString(), 2, '0');
    const occupancyRate =
        props.values.hotelRoomsCount > 0
            ? props.values.bookedRoomsCount / props.values.hotelRoomsCount
            : 0;
    const roundedRate = Math.round(100 * occupancyRate);
    const barStyle = progressBarStyle(props.values.date, roundedRate);
    const cellStyle = dayStyle(props.values.date, selectedDay);
    const trackSelectedDay = () =>
        track({
            eventName: 'selectItem',
            page: 'homePage',
            data: { actionType: 'click', date: props.values.date.toISO() }
        });

    return (
        <a
            className={`calendar-body-date-item ${cellStyle}`}
            href={`/Planning/Edition/Index/${props.values.date.toISODate()}/Room0/Rate0`}
            onClick={trackSelectedDay}
            aria-hidden="true"
        >
            <span className="calendar-body-date-text">{normalisedDay}</span>
            <span className="calendar-body-date-price">&nbsp;</span>
            <div
                className={`avp-progress-bar progress-bar--${barStyle} progress-bar--xsmall ctx-calendar-progress-bar`}
            >
                <div className="progress-bar-inner" style={{ width: `${roundedRate}%` }}></div>
            </div>
        </a>
    );
};

export default CalendarBody;
