import { Spinner } from 'atoms/Spinner';

interface IDaySimpleInfoProps {
    isLoading: boolean;
    header: string;
    title: string;
    description?: string;
}
const DaySimpleInfo = (props: IDaySimpleInfoProps) => {
    return (
        <>
            <div className="key-value-label">
                <span className="key-value-label-text">{props.header}</span>
            </div>
            {props.isLoading ? (
                <Spinner id={''} size={2} align="left" />
            ) : (
                <>
                    <div className="key-value-content">
                        <span className="key-value-content-text">{props.title}</span>
                    </div>
                    <div className="key-value-description">
                        <span className="key-value-description-text">{props.description}</span>
                    </div>
                </>
            )}
        </>
    );
};

export default DaySimpleInfo;
