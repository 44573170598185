import { Card } from 'components/Card/Card';
import { CardItem } from 'components/Card/CardItem';
import { useEffect, useState } from 'react';
import { getHotelOccupancy } from 'repository/hotelOccupancy';
import { ChartOptionTypes, THotelOccupancy } from 'types/hotelOccupancy';
import { globalDashboard } from 'helpers/configHelper';
import { displayPrice } from 'helpers/priceHelper';
import { i18n } from 'helpers/i18nHelper';

import { OccupationChart } from './OccupationChart';

const { labels: DashboardLabels, hotel } = globalDashboard;

const BarChartOccupation = ({ nbDays }: { nbDays: number }): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [occupancyData, setOccupancyData] = useState<THotelOccupancy | null>(null);
    const [chartOptionType] = useState<ChartOptionTypes>(ChartOptionTypes.Rate);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const data = await getHotelOccupancy(nbDays);

            setOccupancyData(data);
            setIsLoading(false);
        };

        fetchData();
    }, [nbDays]);

    const buildChart = (): JSX.Element => {
        return (
            <div className="chart u-margin-top-medium">
                <OccupationChart data={occupancyData} chartOptionType={chartOptionType} />
            </div>
        );
    };

    return (
        <div id="barChartOccupationId" className="occupation-planning">
            <Card
                title={i18n(DashboardLabels.theNextXDays, { number: nbDays })}
                className="u-margin-top-medium"
                option={buildChart()}
                childrenClassName="avp-key-value"
                isLoading={isLoading}
            >
                <>
                    <CardItem
                        label={DashboardLabels.OccupationPlanning.digitalOccupancyRate}
                        content={`${occupancyData?.totalOccupancyPercentage ?? 0}%`}
                        isLoading={isLoading}
                    />

                    <CardItem
                        label={DashboardLabels.OccupationPlanning.provisionalRevenue}
                        content={displayPrice(occupancyData?.totalAmount ?? 0, hotel.mainCurrency)}
                        isLoading={isLoading}
                    />
                </>
            </Card>
        </div>
    );
};

export default BarChartOccupation;
