import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import packageJson from '../package.json';

const root = ReactDOM.createRoot(document.getElementById('dashboard-container') as HTMLElement);

root.render(
    <React.StrictMode>
        <div
            dangerouslySetInnerHTML={{
                __html: `<!-- ${packageJson.name} ${packageJson.version} -->`
            }}
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
        />
        <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
        />
        <link
            rel="stylesheet"
            href="https://extranet-static-files.sandbox.d-edge.com/freshness-extranet-ui-kit/5.10.3/ui-kit.css"
        />
        <App />
    </React.StrictMode>
);
