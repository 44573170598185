import { ESpinnerSize, Spinner } from 'atoms/Spinner';
import { ReactElement } from 'react';

export const Card = ({
    icon,
    title,
    children,
    className = '',
    option,
    childrenClassName = '',
    isLoading = false
}: {
    icon?: string;
    title: string;
    children: ReactElement;
    className?: string;
    option?: JSX.Element;
    childrenClassName?: string;
    isLoading?: boolean;
}): JSX.Element => {
    return (
        <div className={`avp-card ${className}`}>
            <div className="card-content">
                <div className="card-header">
                    {icon && <i className={`card-header-icon far ${icon}`} />}

                    <h2>{title}</h2>
                </div>

                <div className={childrenClassName}>{children}</div>
            </div>

            {option && (
                <div className="card-content">
                    <div hidden={!isLoading}>
                        <Spinner size={ESpinnerSize.big} />
                    </div>

                    <div hidden={isLoading}>{option}</div>
                </div>
            )}
        </div>
    );
};
