import { getLanguage } from './configHelper';

export const displayPrice = (value: number, currency: string) =>
    new Intl.NumberFormat(getLanguage(), {
        style: 'currency',
        currency
    }).format(value);

export const displayPriceNoFraction = (value: number, currency: string) =>
    new Intl.NumberFormat(getLanguage(), {
        style: 'currency',
        currency,
        maximumFractionDigits: 0
    }).format(value);

export const displayPriceNoCurrency = (value: number | null | undefined) =>
    value ? new Intl.NumberFormat(getLanguage()).format(value) : '0';
