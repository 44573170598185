import { FraicheurAnchor } from 'atoms/Anchor';
import { Card } from 'components/Card/Card';
import { getLanguage, globalDashboard } from 'helpers/configHelper';
import { i18n } from 'helpers/i18nHelper';
import { useEffect, useState } from 'react';
import { getHotelBookingsByArrival, getHotelBookingsByDeparture } from 'repository/hotelBookings';
import { THotelBooking } from 'types/hotelBooking';
import PeriodSwitch from 'components/PeriodSwitch';

import { Bookings } from './Bookings';

const { labels: DashboardLabels } = globalDashboard;

enum EDateType {
    Arrival,
    Departure
}

const Stays = (): JSX.Element => {
    const [dateType, setDateType] = useState<EDateType>(EDateType.Arrival);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [arrivals, setArrivals] = useState<THotelBooking[]>([]);
    const [departures, setDepartures] = useState<THotelBooking[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const [_arrivals, _departures] = await Promise.all([
                getHotelBookingsByArrival(),
                getHotelBookingsByDeparture()
            ]);

            setIsLoading(false);
            setArrivals(_arrivals);
            setDepartures(_departures);
        };

        fetchData();
    }, [dateType]);

    const bookings = () => (dateType === EDateType.Arrival ? arrivals : departures);

    const getBookingsURL = () => {
        const url = new URL(`${window.location.origin}/Bookings/Bookings.aspx`);
        url.searchParams.append('mode', 'hotel');
        url.searchParams.append('language', getLanguage());
        return url;
    };

    const getCardTitle = () => {
        const template =
            dateType === EDateType.Arrival
                ? DashboardLabels.Stays.arrivalsTitle
                : DashboardLabels.Stays.departuresTitle;
        return i18n(template, { number: bookings().length });
    };

    return (
        <section id="bookingsSectionId">
            <div className="ctx-home-head u-margin-top-xlarge">
                <div>
                    <h1>{DashboardLabels.Stays.title}</h1>
                </div>
                <div>
                    <FraicheurAnchor
                        id="staysBookingsLinkId"
                        targetFrameName="Bookings"
                        childrenText={DashboardLabels.Stays.bookingLink}
                        href={getBookingsURL().toString()}
                    />
                </div>
            </div>

            <div className="tabs-tab-list">
                <PeriodSwitch
                    periods={[
                        {
                            id: 'staysByArrivalDateButtonId',
                            name: DashboardLabels.Stays.arrivals,
                            isSelected: dateType === EDateType.Arrival,
                            onClick: () => setDateType(EDateType.Arrival)
                        },
                        {
                            id: 'staysByDepartureDateButtonId',
                            name: DashboardLabels.Stays.departures,
                            isSelected: dateType === EDateType.Departure,
                            onClick: () => setDateType(EDateType.Departure)
                        }
                    ]}
                />
            </div>

            <div className="u-margin-top-medium">
                <Card title={getCardTitle()} isLoading={isLoading}>
                    <Bookings bookings={bookings()} isLoading={isLoading} />
                </Card>
            </div>
        </section>
    );
};

export default Stays;
