export type THotelOccupancy = {
    hotelRoomsCount: number;
    totalAmount: number;
    totalOccupancyPercentage: number;
    planningListByDate: TDailyHotelOccupancy[];
};

export type TDailyHotelOccupancy = {
    date: Date;
    occupiedRooms: number;
    rate?: string;
    roomType?: string;
};

export enum ChartOptionTypes {
    Rate = 'Rates',
    RoomType = 'Room Types'
}
