import 'helpers/configHelper';

import Bookings from './components/BookingActivity/BookingActivity';
import OccupationPlanning from './components/OccupancyPlanning/OccupationPlanning';
import Stays from './components/Stays/Stays';
import './App.css';
import pack from '../package.json';

const Infos = () => {
    return <div dangerouslySetInnerHTML={{ __html: `<!-- ${pack.name} ${pack.version} -->` }} />;
};

function App() {
    return (
        <>
            <Infos />
            <OccupationPlanning />
            <Bookings />
            <Stays />
        </>
    );
}

export default App;
