import { Spinner, ESpinnerSize } from 'atoms/Spinner';
import { globalDashboard } from 'helpers/configHelper';
import { shortDate } from 'helpers/dateHelper';
import { i18n } from 'helpers/i18nHelper';
import { displayPrice } from 'helpers/priceHelper';
import { THotelBooking } from 'types/hotelBooking';

interface IBookingsProps {
    isLoading: boolean;
    bookings: THotelBooking[];
}

const { labels: DashboardLabels } = globalDashboard;

export function Bookings(props: IBookingsProps) {
    return props.isLoading ? (
        <Spinner size={ESpinnerSize.big} />
    ) : (
        <table className="avp-table">
            <tbody>
                {props.bookings.map((booking, index) => (
                    <tr
                        key={`booking-${index}`}
                        className={index % 2 === 0 ? '' : 'u-background-cloudy'}
                    >
                        <td key={`booking-${index}-ref`} className="u-font-bold">
                            {booking.reference}
                        </td>
                        <td key={`booking-${index}-rate`}>
                            <span className="avp-badge u-font-bold">{booking.rateCode}</span>
                        </td>
                        <td key={`booking-${index}-stay`}>
                            <i className="far fa-calendar"></i>
                            <span className="u-margin-left-smaller">
                                {shortDate(booking.arrivalDate)}
                            </span>
                            <span className="u-margin-left-smaller">-</span>
                            <span className="u-margin-left-smaller">
                                {shortDate(booking.departureDate)}
                            </span>
                        </td>
                        <td key={`booking-${index}-name`}>{booking.name}</td>
                        <td key={`booking-${index}-guests`}>
                            {/* TODO use a decent i18n lib */}
                            {i18n(DashboardLabels.Stays.guestCount, {
                                guestCount: booking.guestCount
                            })}
                        </td>
                        <td key={`booking-${index}-rooms`}>
                            {/* TODO use a decent i18n lib */}
                            {i18n(DashboardLabels.Stays.roomCount, {
                                roomCount: booking.roomCount
                            })}
                        </td>
                        <td key={`booking-${index}-source`}>{booking.source}</td>
                        <td key={`booking-${index}-price`}>
                            <span className="u-font-bold">
                                {displayPrice(booking.price, booking?.currency || 'EUR')}
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
