import { getRandomDataFromApi, globalDashboard } from 'helpers/configHelper';
import { addDaysToDate, formatDate } from 'helpers/dateHelper';
import { THotelOccupancy } from 'types/hotelOccupancy';

import { fetchData } from './common';

const { hotel: hotelInformation } = globalDashboard;

const IS_RANDOM_DATA = getRandomDataFromApi();

export const getHotelOccupancy = async (days = 7): Promise<THotelOccupancy | null> => {
    const startDate = new Date();
    const endDate = addDaysToDate(new Date(), days);
    return fetchData(
        `Booking/GetHotelBookingOccupancy/${formatDate(startDate)}/${formatDate(
            endDate
        )}/${IS_RANDOM_DATA}`,
        {
            roomCount: hotelInformation.roomCount,
            bookingCurrency: hotelInformation.mainCurrency
        }
    );
};
