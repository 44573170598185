export enum ESpinnerSize {
    small = 1,
    medium = 2,
    big = 3
}

export interface ISpinnerProps {
    id?: string;
    size?: ESpinnerSize;
    align?: 'center' | 'left';
}

export const Spinner = ({
    id = 'spinnerId',
    size = ESpinnerSize.medium,
    align = 'center'
}: ISpinnerProps): JSX.Element => {
    const alignCss = align === 'center' ? 'u-text-center' : 'u-text-left';
    return (
        <div id={id} className={`${alignCss} u-color-tertiary`}>
            <i className={`far fa-circle-notch fa-spin fa-${size}x`}></i>
        </div>
    );
};
