import { selectedDayAtom, bookedRoomsCountAtom } from 'config/jotaiConfig';
import { getLanguage, globalDashboard } from 'helpers/configHelper';
import { displayPriceNoFraction } from 'helpers/priceHelper';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { IDailyStatistic, getDayStatistics } from 'repository/calendar';

import DayBadgedInfo from './DayBadgedInfo';
import DaySimpleInfo from './DaySimpleInfo';

const { labels: DashboardLabels, hotel } = globalDashboard;

const defaultStats: IDailyStatistic = {
    bookedRoomsCount: 0,
    lastYearBookedRoomsCount: 0,
    arrivalCount: 0,
    lastYearArrivalCount: 0,
    mostValuableRateCode: '',
    mostValuableRateName: '',
    lowestRoomPrice: 0,
    lowestRoomName: '',
    highestRoomPrice: 0,
    highestRoomName: ''
};

const prefixNumber = (val: number) => {
    const leading = val > 0 ? '+' : '';
    return `${leading}${val}`;
};

const DayDetails = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [dailyStatistic, setDailyStatistic] = useState<IDailyStatistic>(defaultStats);

    const selectedDay = useAtomValue(selectedDayAtom);
    const globalBookedRoomsCount = useAtomValue(bookedRoomsCountAtom);

    const date = new Intl.DateTimeFormat(getLanguage(), {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
    }).format(selectedDay.toJSDate());

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const dayStatistics = await getDayStatistics(selectedDay);
            setDailyStatistic(dayStatistics);

            setIsLoading(false);
        };

        fetchData();
    }, [selectedDay]);

    const roomsVariation =
        dailyStatistic.lastYearBookedRoomsCount - dailyStatistic.bookedRoomsCount;
    const dailyPickUpVariation = dailyStatistic.arrivalCount - dailyStatistic.lastYearArrivalCount;
    const roomsBookingRatio = dailyStatistic.bookedRoomsCount / hotel.roomCount;

    return (
        <div id="calendarDayDetailsId" className="card-content">
            <div className="card-header ctx-calendar-card-header">
                <h2>{date}</h2>
            </div>
            <div id="dayBookingRatioId" className="avp-key-value">
                {dailyStatistic.lastYearBookedRoomsCount !== 0 ? (
                    <DayBadgedInfo
                        isLoading={isLoading || globalBookedRoomsCount == null}
                        header={DashboardLabels.Calendar.roomForSale}
                        title={`${Math.max(hotel.roomCount - (globalBookedRoomsCount ?? 0), 0)}/${
                            hotel.roomCount
                        }`}
                        badgeInfo={`${prefixNumber(roomsVariation)} ${
                            DashboardLabels.Calendar.yearToYear
                        }`}
                        titleLevel={
                            roomsBookingRatio < 0.3
                                ? 'alert'
                                : roomsBookingRatio < 0.65
                                ? 'warning'
                                : 'success'
                        }
                        badgeLevel={roomsVariation < 0 ? 'success' : 'warning'}
                    />
                ) : (
                    <DaySimpleInfo
                        isLoading={isLoading}
                        header={DashboardLabels.Calendar.roomForSale}
                        title={`${Math.max(hotel.roomCount - (globalBookedRoomsCount ?? 0), 0)}/${
                            hotel.roomCount
                        }`}
                    />
                )}
            </div>
            <div id="dayDailyPickupId" className="avp-key-value u-margin-top-large">
                {dailyStatistic.lastYearArrivalCount !== 0 ? (
                    <DayBadgedInfo
                        isLoading={isLoading}
                        header={DashboardLabels.Calendar.dailyPickUp}
                        title={dailyStatistic.arrivalCount.toString()}
                        badgeInfo={`${prefixNumber(dailyPickUpVariation)} ${
                            DashboardLabels.Calendar.yearToYear
                        }`}
                        titleLevel="neutral"
                        badgeLevel={
                            dailyPickUpVariation > 0
                                ? 'success'
                                : dailyPickUpVariation < 0
                                ? 'warning'
                                : 'neutral'
                        }
                    />
                ) : (
                    <DaySimpleInfo
                        isLoading={isLoading}
                        header={DashboardLabels.Calendar.dailyPickUp}
                        title={dailyStatistic.arrivalCount.toString()}
                    />
                )}
            </div>
            <div id="dayMostValuableRateId" className="avp-key-value u-margin-top-large">
                <DaySimpleInfo
                    isLoading={isLoading}
                    header={DashboardLabels.Calendar.mostValuableRate}
                    title={dailyStatistic.mostValuableRateCode}
                    description={
                        dailyStatistic.mostValuableRateName || dailyStatistic.mostValuableRateCode
                    }
                />
            </div>
            <div id="dayLowestRoomPriceId" className="avp-key-value u-margin-top-large">
                <DaySimpleInfo
                    isLoading={isLoading}
                    header={DashboardLabels.Calendar.lowestRoomPrice}
                    title={displayPriceNoFraction(
                        dailyStatistic.lowestRoomPrice,
                        hotel.mainCurrency
                    )}
                    description={dailyStatistic.lowestRoomName}
                />
            </div>
            <div id="dayHighestRoomPriceId" className="avp-key-value u-margin-top-large">
                <DaySimpleInfo
                    isLoading={isLoading}
                    header={DashboardLabels.Calendar.highestRoomPrice}
                    title={displayPriceNoFraction(
                        dailyStatistic.highestRoomPrice,
                        hotel.mainCurrency
                    )}
                    description={dailyStatistic.highestRoomName}
                />
            </div>
        </div>
    );
};

export default DayDetails;
