import { Spinner } from 'atoms/Spinner';
import { DateTime } from 'luxon';
import { MouseEventHandler } from 'react';
import CalendarBody from 'components/CalendarOccupation/Calendar/CalendarBody';
import { getMonthDayOffset, monthText, shortDayNames } from 'helpers/dateHelper';
import { IDayValues } from 'repository/calendar';

/* eslint no-console: ["error", { allow: ["warn", "error", ] }] */
interface ICalendarMonthProps {
    loading: boolean;
    days: IDayValues[];
    buttonPosition: 'right' | 'left';
    action: MouseEventHandler<HTMLButtonElement>;
}

const monthHash = (date: DateTime) => 100 * date.year + date.month;

const CalendarMonth = (props: ICalendarMonthProps) => {
    const chevron = props.buttonPosition === 'right' ? 'right' : 'left';
    const direction = props.buttonPosition === 'right' ? 'next' : 'previous';
    const firstDay = props.days[0]?.date || DateTime.now();

    // Check all days are in same month
    const hasVariousMonths = props.days.some((day) => monthHash(day.date) !== monthHash(firstDay));
    /* istanbul ignore next */
    if (hasVariousMonths) {
        console.error('Invalid data set given in CalendarMonth');
    }

    return (
        <div className="avp-calendar" data-offset={getMonthDayOffset(firstDay)}>
            <button
                data-testid={`calendar_${direction}_arrow_button`}
                type="button"
                onClick={props.action}
                className={`avp-button calendar-nav-button calendar-nav-button--${direction}`}
            >
                <i className={`fal fa-chevron-${chevron}`}></i>
            </button>
            <div className="calendar-month">{monthText(firstDay)}</div>
            <div className="calendar-heading">
                {shortDayNames.map((dayName) => (
                    <div key={dayName} className="calendar-heading-day">
                        {dayName}
                    </div>
                ))}
            </div>
            <div>
                {props.loading ? (
                    <div style={{ paddingTop: '100px' }}>
                        <Spinner id="" size={3} />
                    </div>
                ) : (
                    <CalendarBody days={props.days} />
                )}
            </div>
        </div>
    );
};

export default CalendarMonth;
