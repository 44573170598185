import { DateTime } from 'luxon';
import { getLanguage, getTimezone } from 'helpers/configHelper';
import { now } from 'helpers/now';

/**
 * # What do we mean by "today"
 *
 * Today is a time interval as follow:
 *   [YYYY-MM-DDT00:00:00.000 (given timezone), YYYY-MM-DDT23:59:59.999 (given timezone)]
 * As defined above, a day is a time interval that depends on a time zone.
 *
 * ## Case 1
 * As an Extranet user, I want to see the arrival list for today
 * - on winter (DST is not applied), just to simplify UTC offset definition in ou example
 * - my hotel is located in Japan (UTC+9)
 * - I am located in Portugal (UTC-1)
 * - current time is 2023-01-23 à 23:10 (UTC-1) == 2023-01-24 à 09:10 (UTC+9)
 *
 * Which arrival dates do I want to list?
 *   * [2023-01-24T00:00:00.000+09:00, 2023-01-24T23:59:59.999+09:00] i.e "local day" in Japan Japon
 *   or
 *   * [2023-01-23T00:00:00.000+09:00, 2023-01-23T23:59:59.999+09:00] i.e "local day" in Portugal
 *
 * ▶ The first one as the "local day" of the hotel in all likelihood
 *
 * ## Case 2
 * The other way arount, my hotel is in Portugal an I am located in Japan
 * Which arrival dates do I want to list?
 *   * [2023-01-24T00:00:00.000+09:00, 2023-01-24T23:59:59.999+09:00] i.e "local day" in Japan Japon
 *   or
 *   * [2023-01-23T00:00:00.000+09:00, 2023-01-23T23:59:59.999+09:00] i.e "local day" in Portugal
 *
 * ▶ The second one as the "local day" of the hotel in all likelihood
 *
 * ## Conclusion
 * We have to calculate dates representing the full current day for the hotel.
 */
export const getTodayIntervalForHotel = () => {
    const zone = getTimezone();

    const truncatedDay = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: zone
    })
        .formatToParts(now())
        .reduce((descriptor, part) => {
            descriptor[part.type] = part.value;
            return descriptor;
        }, {} as Intl.DateTimeFormatPartTypesRegistry);

    // to match API logic where upper interval boundary is included: [start, end]
    const start = DateTime.fromISO(
        `${truncatedDay.year}-${truncatedDay.month}-${truncatedDay.day}T00:00:00.000`,
        { zone }
    );
    const end = start.plus({ day: 1 }).minus({ millisecond: 1 });

    return {
        start: start.toJSDate(),
        end: end.toJSDate()
    };
};

/**
 * A thing that should not be.
 * This exists because Booking report service takes truncated days
 * with not time zone to be understood as date YYYY-MM-DD in hotel timezone.
 * @param date Date
 * @returns an ISO 8601 date without offset like 2023-01-25T16:32:21
 */
export const dateAsAmbiguous = (date: Date) => {
    const t = new Intl.DateTimeFormat('fr', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
        timeZone: getTimezone()
    })
        .formatToParts(date)
        .reduce((descriptor, part) => {
            descriptor[part.type] = part.value;
            return descriptor;
        }, {} as Intl.DateTimeFormatPartTypesRegistry);

    return `${t.year}-${t.month}-${t.day}T${t.hour}:${t.minute}:${t.second}.${t.fractionalSecond}`;
};

export const shortDate = (date: Date): string =>
    new Intl.DateTimeFormat(getLanguage(), {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: getTimezone()
    }).format(date);

// Shouldn't we use a date representation *in* hotel time zone?
export const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const addDaysToDate = (date: Date, days: number): Date => {
    const d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
};

export const isSameDay = (a: DateTime, b: DateTime) => {
    const bb = !a.zone.equals(b.zone) ? b.setZone(a.zone) : b;
    return a.year === bb.year && a.month === bb.month && a.day === bb.day;
};

export const monthText = (date: DateTime) =>
    new Intl.DateTimeFormat(getLanguage(), {
        month: 'long',
        year: 'numeric',
        timeZone: getTimezone()
    }).format(date.toJSDate());

export const getMonthDayOffset = (date: DateTime) => date.startOf('month').weekday;

export const shortDayNames = [...Array(7).keys()].map((i) =>
    new Intl.DateTimeFormat(getLanguage(), {
        weekday: 'short'
    }).format(DateTime.utc(2000, 1, 2).plus({ days: i }).toJSDate())
);
