import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartOptionTypes, THotelOccupancy } from 'types/hotelOccupancy';
import { max, range } from 'lodash';
import { buildSeries, formatDate } from 'helpers/chartHelper';

export const getChartWidth = (): number => {
    const width = document.querySelector('.u-container')?.clientWidth;
    return width && width > 0 ? width * 0.75 : 1000;
};

const computeMaxYAxis = (
    columnCount: number,
    series: {
        name: string;
        data: number[];
    }[],
    factor: number
): number => {
    const columnValueList: number[] = [];
    for (let i = 0; i < columnCount; i++) {
        let columnValue = 0;
        for (let j = 0; j < series.length; j++) {
            columnValue += series[j].data[i];
        }

        columnValueList.push(columnValue);
    }

    return Math.round(factor * (max(columnValueList) ?? 10));
};

export const buildOptions = (
    data: THotelOccupancy | undefined | null,
    optionType: ChartOptionTypes = ChartOptionTypes.RoomType
): any => {
    if (!data) return null;

    const uniqueDates = [
        ...new Set(data.planningListByDate?.map((occupancy) => formatDate(occupancy.date)))
    ];
    const series = buildSeries(data, optionType);
    const columnCount = max(series.map((serie) => serie.data.length)) ?? 0;

    const maxY = computeMaxYAxis(columnCount, series, 1.3);

    return {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        colors: ['#BEB6D1', '#BEB3DB', '#A698CB', '#9384BA', '#70648F', '#544971', '#302944'],
        yAxis: {
            max: maxY,
            visible: true,
            title: {
                text: null
            },
            stackLabels: {
                enabled: false
            },
            labels: {
                enabled: true
            },
            gridLineColor: 'transparent'
        },
        xAxis: {
            categories: uniqueDates,
            labels: {
                useHTML: true,
                style: {
                    textOverflow: 'none',
                    width: 25,
                    textAlign: 'center'
                }
            }
        },
        tooltip: {
            shared: false,
            useHTML: true,
            headerFormat: `<table style="width: 200px"><tr><div style="padding-bottom: 20px"><b>${optionType}</b></div></tr>`,
            pointFormat:
                '<tr><div style="display: flex; margin: 4px 0"><div style="flex-grow: 1">{series.name}</div>' +
                '<div style="color: white; text-align: right; background-color: {series.color}; padding: 0 4px; border-radius: 8px">{point.y} bookings</div></div></tr>',
            footerFormat: '</table>',
            borderWidth: 1,
            borderColor: '#E0E0E0'
        },
        plotOptions: {
            series: {
                pointPadding: 0.05,
                groupPadding: 0.05
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: [
            ...series,
            {
                name: 'Fake Series that shows the max availability',
                data: range(columnCount).map(() => maxY),
                grouping: false,
                stacking: false,
                showInLegend: false,
                enableMouseTracking: false,
                zIndex: -1,
                color: '#F7F5FA'
            }
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        callback: () => true
                    },
                    chartOptions: {
                        chart: {
                            width: getChartWidth()
                        }
                    }
                }
            ]
        },
        accessibility: {
            enabled: false
        }
    };
};

type TOccupationChartProps = {
    data?: THotelOccupancy | null;
    chartOptionType?: ChartOptionTypes;
};

export const OccupationChart = (props?: TOccupationChartProps): JSX.Element => {
    return (
        <div className="chart">
            {props?.chartOptionType === ChartOptionTypes.Rate && (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={buildOptions(props?.data, props?.chartOptionType)}
                />
            )}
        </div>
    );
};
