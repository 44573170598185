import { useState } from 'react';
import { globalDashboard } from 'helpers/configHelper';
import { i18n } from 'helpers/i18nHelper';
import { FraicheurAnchor } from 'atoms/Anchor';
import BarChartOccupation from 'components/BarChartOccupation/BarChartOccupation';
import CalendarOccupation from 'components/CalendarOccupation/CalendarOccupation';
import PeriodSwitch from 'components/PeriodSwitch';

const { labels: DashboardLabels } = globalDashboard;

export type ViewMode = 'calendar' | 'barChart';

const OccupationPlanning = (): JSX.Element => {
    const [viewMode, setViewMode] = useState<ViewMode>('calendar');
    const [nbDays, setNbDays] = useState<number>(7);

    return (
        <section id="bookingOccupancySectionId" className="occupation-planning">
            <div className="ctx-home-head u-margin-top-xlarge">
                <div>
                    <h1>{DashboardLabels.OccupationPlanning.title}</h1>
                    <p className="u-margin-top-medium">
                        {DashboardLabels.OccupationPlanning.subtitle}
                    </p>
                </div>

                <div>
                    <div className="dashboard-last-update">
                        <span className="fa-solid fa-clock" />
                        <span className="u-padding-left-small">
                            {DashboardLabels.BookingActivity.LastUpdateMessage}
                        </span>
                    </div>

                    <FraicheurAnchor
                        id="ManagePlanningDashboardLink"
                        targetFrameName="Planning"
                        href="/Planning/Monthly"
                        childrenText={DashboardLabels.OccupationPlanning.managePlanning}
                    />
                </div>
            </div>

            <div className="tabs-tab-list">
                <PeriodSwitch
                    periods={[
                        {
                            id: 'calendarViewButtonId',
                            name: i18n(DashboardLabels.calendarViewBtn, { number: 7 }),
                            isSelected: viewMode === 'calendar',
                            onClick: () => setViewMode('calendar')
                        },
                        {
                            id: 'barChartViewButtonId',
                            name: i18n(DashboardLabels.barChartViewBtn, { number: 30 }),
                            isSelected: viewMode === 'barChart',
                            onClick: () => setViewMode('barChart')
                        }
                    ]}
                />

                {viewMode === 'barChart' && (
                    <PeriodSwitch
                        periods={[
                            {
                                id: 'nextWeekButtonId',
                                name: i18n(DashboardLabels.nextXDays, { number: 7 }),
                                isSelected: nbDays === 7,
                                onClick: () => setNbDays(7)
                            },
                            {
                                id: 'nextMonthButtonId',
                                name: i18n(DashboardLabels.nextXDays, { number: 30 }),
                                isSelected: nbDays === 30,
                                onClick: () => setNbDays(30)
                            }
                        ]}
                    />
                )}
            </div>

            {viewMode === 'calendar' ? (
                <CalendarOccupation />
            ) : (
                <BarChartOccupation nbDays={nbDays} />
            )}
        </section>
    );
};

export default OccupationPlanning;
