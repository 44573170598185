import { addDaysToDate, formatDate } from 'helpers/dateHelper';
import { getRandomDataFromApi, globalDashboard } from 'helpers/configHelper';
import { THotelActivity } from 'types/hotelActivity';

import { fetchData } from './common';

const { hotel: hotelInformation } = globalDashboard;

const USE_RANDOM_DATA = getRandomDataFromApi();

export const getHotelActivity = async (pastDays = 7): Promise<THotelActivity | null> => {
    const endDate = new Date();
    const startDate = addDaysToDate(new Date(), -pastDays);
    return fetchData(
        `Booking/GetHotelBookingActivity/${formatDate(startDate)}/${formatDate(
            endDate
        )}/${USE_RANDOM_DATA}`,
        {
            bookingCurrency: hotelInformation.mainCurrency
        }
    );
};
