import { Card } from 'components/Card/Card';
import { CardItem } from 'components/Card/CardItem';
import { useEffect, useState } from 'react';
import { getHotelActivity } from 'repository/hotelActivity';
import { THotelActivity } from 'types/hotelActivity';
import { globalDashboard } from 'helpers/configHelper';
import { FraicheurAnchor } from 'atoms/Anchor';
import { displayPrice } from 'helpers/priceHelper';
import PeriodSwitch from 'components/PeriodSwitch';

import './BookingActivity.css';

const { labels: DashboardLabels, hotel } = globalDashboard;

const Bookings = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nbPastDays, setNbPastDays] = useState<number>(7);
    const [bookingData, setBookingData] = useState<THotelActivity | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const data = await getHotelActivity(nbPastDays);
            setBookingData(data);
            setIsLoading(false);
        };

        fetchData();
    }, [nbPastDays]);

    return (
        <section id="BookingActivitySectionId" className="booking-activity">
            <div className="booking-activity ctx-home-head u-margin-top-xlarge">
                <div>
                    <h1>{DashboardLabels.BookingActivity.title}</h1>
                    <p className="u-margin-top-medium">
                        {DashboardLabels.BookingActivity.subtitle}
                    </p>
                </div>

                <div>
                    <div className="dashboard-last-update">
                        <span className="fa-solid fa-clock" />
                        <span className="u-padding-left-small">
                            {DashboardLabels.BookingActivity.LastUpdateMessage}
                        </span>
                    </div>

                    <FraicheurAnchor
                        id="FullReportDashboardLink"
                        targetFrameName="DataCruncher"
                        href="/Analytics/"
                        childrenText={DashboardLabels.BookingActivity.fullReport}
                    />
                </div>
            </div>

            <div className="tabs-tab-list u-margin-top-large">
                <PeriodSwitch
                    periods={[
                        {
                            id: 'pastWeekButtonId',
                            name: DashboardLabels.pastWeek,
                            isSelected: nbPastDays === 7,
                            onClick: () => setNbPastDays(7)
                        },
                        {
                            id: 'pastMonthButtonId',
                            name: DashboardLabels.pastMonth,
                            isSelected: nbPastDays === 30,
                            onClick: () => setNbPastDays(30)
                        }
                    ]}
                />
            </div>

            <div className="ctx-home-booking-activity-cards u-margin-top-medium">
                <Card
                    icon="fa-chart-line"
                    title={DashboardLabels.BookingActivity.revenues}
                    childrenClassName="ctx-home-booking-activity-metrics"
                >
                    <>
                        <CardItem
                            label={DashboardLabels.BookingActivity.dailyPickUp}
                            content={bookingData?.dailyBookingAverage ?? 0}
                            isLoading={isLoading}
                        />

                        <CardItem
                            label={DashboardLabels.BookingActivity.nightsBooked}
                            content={bookingData?.bookedRoomsCount ?? 0}
                            isLoading={isLoading}
                        />

                        <CardItem
                            label={DashboardLabels.BookingActivity.incomes}
                            content={displayPrice(bookingData?.revenue ?? 0, hotel.mainCurrency)}
                            isLoading={isLoading}
                        />
                    </>
                </Card>

                <Card
                    icon="fa-ban"
                    title={DashboardLabels.BookingActivity.cancellations}
                    childrenClassName="ctx-home-booking-activity-metrics"
                >
                    <>
                        <CardItem
                            label={DashboardLabels.BookingActivity.cancellations}
                            content={bookingData?.canceledBookingCount ?? 0}
                            isLoading={isLoading}
                        />

                        <CardItem
                            label={DashboardLabels.BookingActivity.nightsCancelled}
                            content={bookingData?.canceledRoomsCount ?? 0}
                            isLoading={isLoading}
                        />

                        <CardItem
                            label={DashboardLabels.BookingActivity.revenueLost}
                            content={displayPrice(
                                bookingData?.canceledRevenue ?? 0,
                                hotel.mainCurrency
                            )}
                            isLoading={isLoading}
                        />
                    </>
                </Card>

                <div className="avp-card dashboard-app-card">
                    <a id="DataCruncherDashboardLink" target="DataCruncher" href="/Analytics/">
                        <div className="dashboard-app-left">
                            <div className="dashboard-app-logo-datacruncher" />
                        </div>
                        <div className="dashboard-app-right">
                            <h4 className="dashboard-app-title">
                                {DashboardLabels.BookingActivity.DataCruncherTitle}
                            </h4>
                            <p className="dashboard-app-text">
                                {DashboardLabels.BookingActivity.DataCruncherText}
                            </p>
                        </div>
                        <p className="dashboard-app-link">
                            {DashboardLabels.BookingActivity.DataCruncherLink}
                            <span className="fa-solid fa-arrow-right u-margin-left-medium" />
                        </p>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Bookings;
