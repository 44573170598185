import { getRandomDataFromApi } from 'helpers/configHelper';
import { dateAsAmbiguous, getTodayIntervalForHotel } from 'helpers/dateHelper';
import { THotelBooking } from 'types/hotelBooking';
import { fetchData } from 'repository/common';
import { isNil } from 'lodash';

/* eslint no-console: ["error", { allow: ["warn", "error", ] }] */
const USE_RANDOM_DATA = getRandomDataFromApi();

class HotelBookingsQuery {
    public arrivalStartDate?: Date;
    public arrivalEndDate?: Date;
    public departureStartDate?: Date;
    public departureEndDate?: Date;
    public maxLineCount?: number;
    public pageNumber?: number;
    public isRandom?: boolean;

    constructor() {
        this.pageNumber = 0;
        this.maxLineCount = 20;
    }

    public asKv(): any {
        return Object.keys(this).reduce((kvs, k) => {
            const value = (this as any)[k];
            if (isNil(value)) return kvs;
            kvs[k] =
                value?.constructor.name === 'Date' ? dateAsAmbiguous(value as Date) : `${value}`;
            return kvs;
        }, {} as any);
    }
}

export const getHotelBookingsByArrival = async (): Promise<THotelBooking[]> => {
    const today = getTodayIntervalForHotel();
    const query = Object.assign(new HotelBookingsQuery(), {
        arrivalStartDate: today.start,
        arrivalEndDate: today.end
    });
    return getHotelBookings(query);
};

export const getHotelBookingsByDeparture = async (): Promise<THotelBooking[]> => {
    const today = getTodayIntervalForHotel();
    const query = Object.assign(new HotelBookingsQuery(), {
        departureStartDate: today.start,
        departureEndDate: today.end
    });
    return getHotelBookings(query);
};

const getHotelBookings = async (query: HotelBookingsQuery): Promise<THotelBooking[]> => {
    query.isRandom = USE_RANDOM_DATA;
    const rawData = await fetchData('Booking/GetHotelBookings', query.asKv());
    return dummyType(rawData as any[]);
};

// TODO Use a decent tool to check and type data
const dummyType = (rawBookings: unknown) => {
    if (!Array.isArray(rawBookings)) {
        console.error('Bad response from API. Array expected.');
        return [];
    }

    return (rawBookings as any[]).map((booking: any) => {
        booking.arrivalDate = new Date(booking.arrivalDate);
        booking.departureDate = new Date(booking.departureDate);
        return booking;
    });
};
