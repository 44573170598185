import Calendar from './Calendar/Calendar';
import DayDetails from './DayDetails/DayDetails';
import './CalendarStyle.css';

const CalendarOccupation = () => {
    return (
        <div className="avp-card u-margin-top-medium">
            <DayDetails />
            <Calendar />
        </div>
    );
};

export default CalendarOccupation;
