import { generateApiUrl, globalDashboard } from 'helpers/configHelper';

const getOptions = () => {
    return globalDashboard.settings.extranet === 'false'
        ? {
              headers: {
                  'X-DEdge-User-Identity': '{"userId":13130,"groupId":11467,"hotelId":15102}',
                  'X-DEdge-Api-Key': globalDashboard.settings.apiKey || '',
                  'Accept-Language': globalDashboard.language || 'en'
              }
          }
        : undefined;
};

export const fetchData = async (path: string, query?: any) => {
    const url = generateApiUrl(buildURL(path, query));
    const response = await fetch(url, getOptions());
    return response.json();
};

const buildURL = (path: string, query?: any) => {
    if (!query || Object.keys(query).length < 1) return path;
    const queryParams = new URLSearchParams(query);
    const separator = path.includes('?') ? '&' : '?';
    return `${path}${separator}${queryParams}`;
};
