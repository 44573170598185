/**
 * This is a dummy module to interpolate strings from a template
 * Would prefer to use a library
 */
// export type TI18nValues = TKeyValues | TMixedArray;

// type TNullOrStringables = number | string | object | null | undefined;

// type TMixedArray = {
//     [key: number]: TNullOrStringables
// }
// type TKeyValues = {
//      // anything with a toString function or null or undefined
//     [key: string]: TNullOrStringables
// }

export const invalidTpl = '[invalid-i18n-template]';

// export const i18n = (template: string, objValues: TI18nValues) => {
export const i18n = (template: string, objValues: any) => {
    if (typeof template !== 'string' || !template) return invalidTpl;

    return !objValues
        ? template
        : Object.keys(objValues).reduce((interpolated, key) => {
              interpolated = interpolated.replaceAll(
                  `{${key}}`,
                  stringable(objValues[key]).toString()
              );
              return interpolated;
          }, template);
};

const stringable = (smth: any) => (smth === null || smth === undefined ? '' : smth);
