import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { IDayValues, getCalendarOverview } from 'repository/calendar';
import { useAtom, useSetAtom } from 'jotai';
import { selectedDayAtom, bookedRoomsCountAtom } from 'config/jotaiConfig';

import CalendarMonth from './CalendarMonth';

type MoveMonthDirection = 'forward' | 'backward';

const Calendar = () => {
    // TODO use "now" function from helper?
    const startOfMonth = DateTime.now().startOf('month');
    const [startDay, setStartDay] = useState(startOfMonth);
    const [isLoading, setIsLoading] = useState(false);
    const [calendarLeft, setCalendarLeft] = useState<IDayValues[]>([]);
    const [calendarRight, setCalendarRight] = useState<IDayValues[]>([]);
    const [isFirstDaySet, setIsFirstDaySet] = useState<boolean>(false);

    const [selectedDay] = useAtom(selectedDayAtom);
    const setBookedRoomsCount = useSetAtom(bookedRoomsCountAtom);

    const initBookedRoomsCount = useCallback(
        (data: IDayValues[]) => {
            // At the first calendar render, we init the today's booked rooms count as we do not trust the one from statistic yet
            if (!isFirstDaySet && selectedDay) {
                const [firstSelectedDay] = data.filter(
                    (dailyData) => dailyData.date.toISODate() === selectedDay.toISODate()
                );
                if (firstSelectedDay) {
                    setBookedRoomsCount(firstSelectedDay.bookedRoomsCount);
                    setIsFirstDaySet(true);
                }
            }
        },
        [isFirstDaySet, selectedDay, setBookedRoomsCount]
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const endDay = startDay.plus({ months: 1 }).endOf('month');
            const data = await getCalendarOverview({ startDay, endDay });

            setCalendarLeft(data.filter((entry) => entry.date.month === startDay.month));
            setCalendarRight(data.filter((entry) => entry.date.month === endDay.month));

            initBookedRoomsCount(data);

            setIsLoading(false);
        };

        fetchData();
    }, [startDay, initBookedRoomsCount]);

    const moveMonth = (direction: MoveMonthDirection) => {
        if (direction === 'forward') {
            setStartDay(startDay.plus({ months: 1 }).startOf('month'));
        }
        if (direction === 'backward') {
            setStartDay(startDay.plus({ months: -1 }).startOf('month'));
        }
    };

    return (
        <div id="CalendarOverviewId" className="card-content ctx-home-calendar-container">
            <CalendarMonth
                days={calendarLeft}
                loading={isLoading}
                buttonPosition="left"
                action={() => moveMonth('backward')}
            />
            <CalendarMonth
                days={calendarRight}
                loading={isLoading}
                buttonPosition="right"
                action={() => moveMonth('forward')}
            />
        </div>
    );
};

export default Calendar;
