import { globalDashboard } from 'helpers/configHelper';

const { labels: DashboardLabels } = globalDashboard;

interface PeriodSwitchInput {
    periods: {
        id: string;
        name: string;
        isSelected: boolean;
        onClick: () => void;
    }[];
}

const PeriodSwitch = (props: PeriodSwitchInput) => {
    return (
        <div className="avp-tabs tabs--primary u-margin-top-large tabs-tab">
            <span>{DashboardLabels.period}:</span>

            {props.periods.map((period) => (
                <button
                    key={period.id}
                    id={period.id}
                    className="tabs-tab"
                    type="button"
                    aria-selected={period.isSelected}
                    onClick={period.onClick}
                >
                    {period.name}
                </button>
            ))}
        </div>
    );
};

export default PeriodSwitch;
