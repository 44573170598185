import { clone, groupBy, sum } from 'lodash';
import { ChartOptionTypes, TDailyHotelOccupancy, THotelOccupancy } from 'types/hotelOccupancy';

export const formatDate = (date: Date): string => {
    return new Date(date).toLocaleDateString(window.navigator.language.toLocaleLowerCase());
};

export const DEFAULT_OPTION_VALUE = 'Other';
export const MAX_OPTION_COUNT = 6;

const getOptionToCompare = (
    dailyOccupancy: TDailyHotelOccupancy,
    optionType: ChartOptionTypes
): string => {
    return (
        (optionType === ChartOptionTypes.RoomType
            ? dailyOccupancy.roomType
            : dailyOccupancy.rate) ?? DEFAULT_OPTION_VALUE
    );
};

const computeOccupancyByDateAndOption = (
    data: THotelOccupancy,
    uniqueOption: string,
    date: string,
    optionType: ChartOptionTypes
): number => {
    const filteredData = data.planningListByDate.filter(
        (dailyOccupancy) =>
            formatDate(dailyOccupancy.date) === date &&
            getOptionToCompare(dailyOccupancy, optionType) === uniqueOption
    );
    const occupancies = filteredData.map((dailyOccupancy) => dailyOccupancy.occupiedRooms);

    if (occupancies.length > 0) {
        return occupancies.reduce((curr, prev) => curr + prev, 0);
    }

    return 0;
};

export const getOptionsToShow = (
    hotelOccupancy: TDailyHotelOccupancy[],
    optionType: ChartOptionTypes,
    maxOptionCount = MAX_OPTION_COUNT
): string[] => {
    const optionMap = groupBy(hotelOccupancy, (dailyOccupancy) =>
        getOptionToCompare(dailyOccupancy, optionType)
    );

    const uniqueOptionsSortedByCount = Object.keys(optionMap)
        .map((option) => ({
            name: option,
            count: sum(optionMap[option].flatMap((occupancy) => occupancy.occupiedRooms))
        }))
        .sort((option1, option2) => option2.count - option1.count);

    const optionsToKeep = uniqueOptionsSortedByCount.map((option) => option.name);

    // In the case initial data is already containing an other, we need to take another option (to always have MAX_OPTION_COUNT values)
    if (optionsToKeep.includes(DEFAULT_OPTION_VALUE)) {
        maxOptionCount = maxOptionCount + 1;
    }

    return optionsToKeep.slice(0, maxOptionCount);
};

export const replaceLowestOptionByOther = (
    uniqueOptionsToShow: string[],
    dailyOccupancy: TDailyHotelOccupancy,
    optionType: ChartOptionTypes
): TDailyHotelOccupancy => {
    const currentOccupancy = clone(dailyOccupancy);

    if (
        optionType === ChartOptionTypes.Rate &&
        !uniqueOptionsToShow.includes(dailyOccupancy.rate ?? '')
    ) {
        currentOccupancy.rate = DEFAULT_OPTION_VALUE;
    }

    if (
        optionType === ChartOptionTypes.RoomType &&
        !uniqueOptionsToShow.includes(dailyOccupancy.roomType ?? '')
    ) {
        currentOccupancy.roomType = DEFAULT_OPTION_VALUE;
    }

    return currentOccupancy;
};

export const buildSeries = (
    hotelOccupancy: THotelOccupancy,
    optionType: ChartOptionTypes = ChartOptionTypes.RoomType
) => {
    const uniqueOptions = [
        ...new Set(
            hotelOccupancy?.planningListByDate.map((dailyOccupancy) =>
                getOptionToCompare(dailyOccupancy, optionType)
            )
        )
    ];
    const uniqueOptionsToShow = getOptionsToShow(hotelOccupancy.planningListByDate, optionType);

    // Remove lower options to keep only the highest
    if (uniqueOptions.length > MAX_OPTION_COUNT) {
        hotelOccupancy.planningListByDate = hotelOccupancy.planningListByDate.map(
            (dailyOccupancy) =>
                replaceLowestOptionByOther(uniqueOptionsToShow, dailyOccupancy, optionType)
        );

        if (!uniqueOptionsToShow.includes(DEFAULT_OPTION_VALUE)) {
            uniqueOptionsToShow.push(DEFAULT_OPTION_VALUE);
        }
    }

    const uniqueDates = [
        ...new Set(hotelOccupancy?.planningListByDate.map((planning) => formatDate(planning.date)))
    ];

    const series = uniqueOptionsToShow?.map((option) => ({
        name: option,
        data: Array.from({ length: uniqueDates.length }, () => 0).map((_, idx) =>
            computeOccupancyByDateAndOption(hotelOccupancy, option, uniqueDates[idx], optionType)
        )
    }));

    return series;
};
