export interface IFraicheurAnchorProps {
    readonly childrenText: string;
    readonly href: string;
    readonly targetFrameName: string;
    readonly id: string;
}

export const FraicheurAnchor = (props: IFraicheurAnchorProps) => (
    <a
        className="avp-button button--primary button--solid button--medium u-text-truncate"
        type="button"
        id={props.id}
        target={props.targetFrameName}
        href={props.href}
    >
        {props.childrenText}
    </a>
);
