import { DateTime } from 'luxon';
import { isSameDay, formatDate } from 'helpers/dateHelper';
import { globalDashboard } from 'helpers/configHelper';

import { fetchData } from './common';

const { hotel: hotelInformation } = globalDashboard;

export interface ICalendarOverviewParams {
    startDay: DateTime;
    endDay: DateTime;
}

export const getCalendarOverview = async (params: ICalendarOverviewParams) => {
    if (params.endDay.diff(params.startDay, 'days').days < 1) return [];

    const days: IDayValues[] = (await getDaysValues(params)).map((day) => ({
        ...day,
        ...{ date: DateTime.fromISO(day.date, { setZone: true }) },
        ...{ hotelRoomsCount: hotelInformation.roomCount }
    }));

    const allDays = dayRange(params.startDay, params.endDay).map((day) => {
        const candidate = days.find((d) => isSameDay(day, d.date));
        return !candidate ? { date: day, bookedRoomsCount: 0, hotelRoomsCount: 0 } : candidate;
    });

    return allDays;
};

const dayRange = (start: DateTime, end: DateTime) => {
    const days = [];
    let current = start;
    while (current.diff(end, 'days').days < 0) {
        days.push(current);
        current = current.plus({ days: 1 });
    }
    return days;
};

export interface IDayValues {
    date: DateTime;
    bookedRoomsCount: number;
    hotelRoomsCount: number;
}

interface IRawDayValues {
    date: string;
    bookedRoomsCount: number;
    hotelRoomsCount: number;
}

const getDaysValues = async (params: ICalendarOverviewParams): Promise<IRawDayValues[]> => {
    if (process.env.REACT_APP_V2_PATH === 'true') {
        return fetchData('Booking/Calendar', calendarOverviewParamsToKv(params));
    }
    return fetchData(
        `Booking/GetHotelBookingCalendar/${formatDate(params.startDay.toJSDate())}/${formatDate(
            params.endDay.toJSDate()
        )}`,
        {
            bookingCurrency: hotelInformation.mainCurrency
        }
    );
};

const calendarOverviewParamsToKv = (params: ICalendarOverviewParams) => ({
    startDay: formatDate(params.startDay.toJSDate()),
    endDay: formatDate(params.endDay.toJSDate()),
    bookingCurrency: hotelInformation.mainCurrency
});

export interface IDailyStatistic {
    bookedRoomsCount: number;
    lastYearBookedRoomsCount: number;

    arrivalCount: number;
    lastYearArrivalCount: number;

    mostValuableRateCode: string;
    mostValuableRateName: string;

    lowestRoomPrice: number;
    lowestRoomName: string;

    highestRoomPrice: number;
    highestRoomName: string;
}

export interface IDailyStatisticParams {
    selectedDay: string;
    bookingCurrency: string;
}

export const getDayStatistics = async (selectedDay: luxon.DateTime): Promise<IDailyStatistic> => {
    if (process.env.REACT_APP_V2_PATH === 'true') {
        return fetchData('Booking/CalendarStatistics', {
            selectedDay: selectedDay.toISO(),
            bookingCurrency: hotelInformation.mainCurrency
        });
    }

    return fetchData(
        `Booking/GetHotelBookingCalendar/Statistics/${formatDate(selectedDay.toJSDate())}`,
        {
            bookingCurrency: hotelInformation.mainCurrency
        }
    );
};
