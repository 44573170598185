import { IEvent } from '@d-edge/tracking';

type EventAction = 'selectItem';
type PageType = 'homePage';

interface ActionData {
    actionType: string;
    [k: string]: string;
}

export type ModifyItemTrackingType = IEvent<ActionData, PageType> & { eventName: EventAction };

const track = ({ eventName, data, page }: ModifyItemTrackingType) => {
    const win = window as any;
    /* istanbul ignore next */
    if (win?.Tracker) {
        win.Tracker.trackEvent({ eventName, data, page });
    } else {
        // eslint-disable-next-line no-console
        console.warn('can not track as Tracker is not set in current context');
    }
};

export default track;
